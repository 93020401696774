






















































import CommonRulesSettings from '@/views/chat/filters/components/SettingGroups/CommonRulesSettings.vue'
import VirtualNewSettings from '@/views/chat/filters/components/Fields/VirtualNewSettings.vue'
import CommonSettings from '@/views/chat/filters/components/SettingGroups/CommonSettings.vue'
import FilterLevels from '@/views/chat/filters/components/FilterLevels.vue'
import { ExtraSettingsMenuItems } from '@/views/chat/filters/FilterTypes'
import StrictMode from '@/views/chat/filters/components/Fields/StrictMode.vue'
import HandleModified from '@/views/chat/filters/components/Fields/HandleModified.vue'
import IgnoreCaption from '@/views/chat/filters/components/Fields/IgnoreCaption.vue'
import HandleUserBio from '@/views/chat/filters/components/Fields/HandleUserBio.vue'
import SectionWrapper from '@/views/chat/filters/components/SectionWrapper.vue'
import FilterViewMixin from '@/views/chat/filters/include/FilterViewMixin'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    HandleUserBio,
    IgnoreCaption,
    HandleModified,
    StrictMode,
    CommonRulesSettings,
    VirtualNewSettings,
    CommonSettings,
    FilterLevels,
    SectionWrapper
  },
  data() {
    return {
      ExtraSettingsMenuItems
    }
  }
})
export default class StoryView extends Mixins<UseFields, InputSetups, FilterViewMixin>(UseFields, InputSetups, FilterViewMixin) {
}
